import { FetchMonitorLocationsQuery, FetchMonitorLocationsQueryVariables } from 'app/__generated__/global';
import { FETCH_MONITOR_LOCATIONS } from 'app/business-logic/services/monitor-service/graphql/facility.fetchMonitorLocations';
import { LogManager } from 'core/logging/LogManager';
import { ApolloProvider } from 'core/net/ApolloProvider';
import Guid from 'core/types/Guid';

const logger = LogManager.getLogger('fetchMonitorLocations');

type LocationHistoryWithActiveFromTime = {
  latitude: number;
  longitude: number;
  activeFromTime: string;
  elevation?: number | null;
};

export const fetchMonitorLocations = async ({ processIds }: { processIds: Guid[] }) => {
  const { data, errors } = await ApolloProvider.global().query<
    FetchMonitorLocationsQuery,
    FetchMonitorLocationsQueryVariables
  >({
    query: FETCH_MONITOR_LOCATIONS,
    variables: { processFilter: { id_in: processIds } },
    errorPolicy: 'ignore',
  });

  if (errors || !data.facility?.processes) {
    throw new Error(`Failed to get monitor locations`);
  }

  const { withoutLocationHistory, withLocationHistory } = separateByLocationHistory(data.facility.processes);

  if (withoutLocationHistory.length) {
    logger.warn(`The following monitors have no location history and will be filtered out:`, withoutLocationHistory);
  }

  return withLocationHistory;
};

function separateByLocationHistory(processes: NonNullable<FetchMonitorLocationsQuery['facility']>['processes']) {
  const withoutLocationHistory: Array<{ id: Guid }> = [];
  const withLocationHistory: Array<{
    id: Guid;
    locationHistory: Array<LocationHistoryWithActiveFromTime>;
  }> = [];

  processes.forEach(monitor => {
    const { id } = monitor;
    if (!('locationHistory' in monitor)) {
      withoutLocationHistory.push({ id: monitor.id });
      return;
    }
    const validatedLocationHistory =
      monitor.locationHistory?.flatMap(({ activeFromTime, latitude, longitude, elevation }) => {
        if (!activeFromTime) return [];
        return {
          activeFromTime,
          latitude,
          longitude,
          elevation,
        };
      }) ?? [];
    if (!validatedLocationHistory.length) {
      withoutLocationHistory.push({ id: monitor.id });
      return;
    }
    withLocationHistory.push({
      id,
      locationHistory: validatedLocationHistory,
    });
  });

  return {
    withoutLocationHistory,
    withLocationHistory,
  };
}
